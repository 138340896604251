import React, { useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

function UnderDevelopment() {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.fullName.trim()) {
            formErrors.fullName = "Full name is required";
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(formData.email)) {
            formErrors.email = "Invalid email format";
            isValid = false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.phoneNumber) {
            formErrors.phoneNumber = "Phone number is required";
            isValid = false;
        } else if (!phoneRegex.test(formData.phoneNumber)) {
            formErrors.phoneNumber = "Phone number should be 10 digits";
            isValid = false;
        }

        if (!formData.message.trim()) {
            formErrors.message = "Message is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted:', formData);
        }
    };

    return (
        <div className="main-layout">
            <div className="container">
                <Header />
                {/* <div className="future-of-startups">
                                <h1>We're here to assist you.</h1>
                            </div> */}
                <div className=" under-devlopment-main">
                <section className="under-devlopment-bottom">
                    <div className="container">
                        <div className="under-devlopment-main-text">
                        <img src="/assets/images/underdevlopment_image.svg" alt="img" className="" />

                            <h2 className="heading">We are under development</h2>
                            <h1 className="heading">We’re working hard to bring you something amazing. Stay tuned for updates!</h1>
                            <h6>🖤 Team BizDateUp Technologies Pvt. Ltd.</h6>
                        </div>
                    </div>
                </section>
                </div>
                
                
                <Footer />
            </div>
        </div>

    )
}

export default UnderDevelopment;
