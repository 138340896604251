import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'bootstrap-icons/font/bootstrap-icons.css';
import "./assets/css/App.css";
import "./assets/css/cardScroll.css";
import "./assets/css/EllipseAnimation.css";

import Home from './pages/Home';
import StartAutoScroll from './pages/startAutoScroll';
import TabsLayout from './pages/TabsLayout';
import Blogs from './pages/Blogs';
import News from './pages/news';
import BlogDetails from './pages/blogDetails';
import ContactUs from './pages/contactus';
import UnderDevelopment from './pages/comming';
import Newsnew from './pages/news1';
import Faqpage from './pages/faq';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/scroll" element={<StartAutoScroll />} />
        <Route path="/explore" element={<TabsLayout />} />
        <Route path="/blogs" element={<Blogs/>}/>
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/blog" element={<News/>}/>
        <Route path="/News" element={<Newsnew/>}/>
        <Route path="/Faq" element={<Faqpage/>}/>
        <Route path="/UnderDevelopment" element={<UnderDevelopment/>}/>
        <Route path="/blogs/blog-details/:id" element={<BlogDetails />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;