import React, { useState } from 'react';

const EllipseAnimation = ({ items }) => {
  const [showTooltip, setShowTooltip] = useState(null);

  const handleMouseEnter = (index) => {
    setShowTooltip(index);
  };

  const handleMouseLeave = () => {
    setShowTooltip(null);
  };

  return (
    <section className="ellipse-main-container">
      <div className="ellipse-main">
        <div className="big-circle">
          {items.slice(0, 3).map((icon, index) => (
            <div
              key={index}
              className="icon-block"
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <img src={icon.src} alt={icon.alt} />
              {showTooltip === index  && icon.tooltip !== '' && <div className="tooltip">{icon.tooltip}<br/>{icon.amount}</div>}
            </div>
          ))}
        </div>
        <div className="circle">
          {items.slice(3, 7).map((icon, index) => (
            <div
              key={index}
              className="icon-block"
              onMouseEnter={() => handleMouseEnter(index + 2)}
              onMouseLeave={handleMouseLeave}
            >
              <img src={icon.src} alt={icon.alt} />
              {showTooltip === index + 2 && icon.tooltip !== '' && <div className="tooltip">{icon.tooltip}<br/>{icon.amount}</div>}
            </div>
          ))}
        </div>
        <div className="mini-circle">
          {items.slice(7, 11).map((icon, index) => (
            <div
              key={index}
              className="icon-block"
              onMouseEnter={() => handleMouseEnter(index + 5)}
              onMouseLeave={handleMouseLeave}
            >
              <img src={icon.src} alt={icon.alt} />
              {showTooltip === index + 5 && icon.tooltip !== '' && <div className="tooltip">{icon.tooltip}<br/>{icon.amount}</div>}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default EllipseAnimation;

