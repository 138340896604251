import React, { useRef, useEffect, useState } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";
import FAQAccordion from "../component/FAQAccordion";

function Faqpage() {

    return (
        <div className="main-layout">
            <Header />
            <section className="faq-section">
                <div className="container">
                    <div className="text-center">
                        <h4 className="heading">Frequently asked questions</h4>
                        <p className="content mt-3">Everything you need to know about the product and billing.</p>
                    </div>
                    <FAQAccordion />
                </div>
            </section>
            <Footer />
        </div>
    )
}

export default Faqpage;