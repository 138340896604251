import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Header from '../component/Header';
import Footer from '../component/Footer';
import AllBlogPosts from '../component/AllBlogPosts';
import RecentBlogPosts from '../component/RecentBlogPosts';
import blogsData from '../data/blogsData.json';

function BlogDetails() {
    const navigate = useNavigate();
    const { id } = useParams();
    const blog = blogsData[id];
    return (
        <div className="main-layout">
            <Header />
            <section className="blogs-section blog-details-section">
                <div className="container all-blogs-section">
                    <button onClick={() => navigate('/blog')} className="icon-img">
                        <img src="/assets/images/back-arrow.svg" alt="Back" />
                        Back
                    </button>
                    <img src={blog.src} className="img-fluid mt-5 mb-5" />
                    <h4 className="subheading">{blog.date}</h4>
                    <h1 className="heading">{blog.subheading}</h1>
                    <p className="content">{blog.subheading1}</p>
                    <p className="content">{blog.content2}</p>
                    <h1 className="heading-subheading">{blog.subheading2}</h1>
                    <p className="content">{blog.content3}</p>
                    <h1 className="heading-subheading">{blog.subheading3}</h1>
                    <h1 className="heading-subheading">{blog.subheading4}</h1>
                    <p className="content">{blog.content4}</p>
                    <p className="content">{blog.content5}</p>
                    <p className="content">{blog.content6}</p>
                    <p className="content">{blog.content7}</p>
                    <p className="content">{blog.content8}</p>
                    <h1 className="heading-subheading">{blog.subheading5}</h1>
                    <p className="content">{blog.content9}</p>
                    <h1 className="heading-subheading">{blog.subheading6}</h1>
                    <p className="content">{blog.content10}</p>
                    <h1 className="heading-subheading">{blog.subheading7}</h1>
                    <p className="content">{blog.content11}</p>
                    <h1 className="heading-subheading">{blog.subheading8}</h1>
                    <p className="content">{blog.content12}</p>
                    <h1 className="heading-subheading">{blog.subheading9}</h1>
                    <p className="content">{blog.content13}</p>
                    <h1 className="heading-subheading">{blog.subheading10}</h1>
                    <h1 className="heading-subheading">{blog.subheading11}</h1>
                    <p className="content">{blog.content14}</p>
                    <h1 className="heading-subheading">{blog.subheading12}</h1>
                    <p className="content">{blog.content15}</p>
                    <h1 className="heading-subheading">{blog.subheading13}</h1>
                    <p className="content">{blog.content16}</p>
                    <h1 className="heading-subheading">{blog.subheading14}</h1>
                    <p className="content">{blog.content17}</p>
                    <h1 className="heading-subheading">{blog.subheading15}</h1>
                    <p className="content">{blog.content18}</p>
                    <h1 className="heading-subheading">{blog.subheading16}</h1>
                    <p className="content">{blog.content19}</p>
                    <p className="content">{blog.content20}</p>
                    <p className="content">{blog.content21}</p>
                    <p className="content">{blog.content22}</p>
                    <h1 className="heading-subheading">{blog.subheading17}</h1>
                    <p className="content">{blog.content23}</p>
                    <p className="content">{blog.content24}</p>
                    <h1 className="heading-subheading">{blog.subheading18}</h1>
                    <p className="content">{blog.content25}</p>
                    <p className="content">{blog.content26}</p>
                    <p className="content">{blog.content27}</p>
                    <p className="content">{blog.content28}</p>
                    <h1 className="heading-subheading">{blog.subheading19}</h1>
                    <p className="content">{blog.content29}</p>
                    <p className="content">{blog.content30}</p>
                    <p className="content">{blog.content31}</p>
                    <p className="content">{blog.content32}</p>
                    <h1 className="heading-subheading">{blog.subheading20}</h1>
                    <p className="content">{blog.content33}</p>
                </div>
            </section>
            {/* <RecentBlogPosts /> */}
            <AllBlogPosts />
            <Footer />
        </div>
    )
}

export default BlogDetails;
