import React, { useState } from 'react';
import Header from '../component/Header';
import Footer from '../component/Footer';

function ContactUs() {
    const [formData, setFormData] = useState({
        fullName: '',
        email: '',
        phoneNumber: '',
        message: ''
    });
    const [errors, setErrors] = useState({});

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({
            ...formData,
            [id]: value
        });
    };

    const validateForm = () => {
        let formErrors = {};
        let isValid = true;

        if (!formData.fullName.trim()) {
            formErrors.fullName = "Full name is required";
            isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email) {
            formErrors.email = "Email is required";
            isValid = false;
        } else if (!emailRegex.test(formData.email)) {
            formErrors.email = "Invalid email format";
            isValid = false;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!formData.phoneNumber) {
            formErrors.phoneNumber = "Phone number is required";
            isValid = false;
        } else if (!phoneRegex.test(formData.phoneNumber)) {
            formErrors.phoneNumber = "Phone number should be 10 digits";
            isValid = false;
        }

        if (!formData.message.trim()) {
            formErrors.message = "Message is required";
            isValid = false;
        }

        setErrors(formErrors);
        return isValid;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Form submitted:', formData);
        }
    };

    return (
        <div className="main-layout">
            <div className="container">
                <Header />
                {/* Contact us Content */}
                {/* <section className="hero-section">
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center">
                            <img src="/assets/images/contactus-hero.png" class="img-fluid mx-auto h-50" />
                        </div>
                        <div className="col-lg-6">
                            <div className="future-of-startups">
                                <h1>Get in touch with us. We're here to assist you.</h1>
                            </div>
                        </div>
                    </div>
                </section> */}
                <div className="future-of-startups-contect">
                                <h1 className='contect-us-heading'>We're here to assist you.</h1>
                            </div>
                <div className=" contect-form-main">
                <section className="contact-us-bottom">
                    <div className="container">
                        <div className="">
                            <h6 className="heading">Contact Information</h6>
                            <p className="heading">Sukh Sagar, CHS Limited, 605, N S Patkar Marg,<br></br> Charni Road, Sea Face, Gamdevi,<br></br> Mumbai, Maharashtra 400007</p>
                        </div>
                        <div className="">
                            <h6 className="heading">Email Address</h6>
                            

                            <h6><a href="mailto:support@bizdateup.com" class="black-link">support@bizdateup.com</a></h6>
                            <p>Assistance hours:<br />
                                Mon - Sat : 10:00-7:00<br></br>Sunday : Closed</p>
                        </div>
                        <div className="">
                            <h6 className="heading">Number</h6>
                            <h6><a href="tel://+917738583751" onclick="window.open('thispage.htm','_self');" class="black-link">+91 77385 83751</a></h6>
                            
                        </div>
                    </div>
                </section>
                <section className="contact-form">
                    <div className="container">
                        <form className="contact-form-inputs" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className=" ">
                                    <div className="d-flex flex-column">
                                        <label htmlFor="full-name">Full Name</label>
                                        <input
                                            id="fullName"
                                            type="text"
                                            className="input-box"
                                            placeholder="Enter Full Name"
                                            value={formData.fullName}
                                            onChange={handleInputChange}
                                        />
                                        {errors.fullName && <span className="error">{errors.fullName}</span>}
                                    </div>
                                </div>

                                <div className=" mt-5">
                                    <div className="d-flex flex-column">
                                        <label htmlFor="email">Email Id</label>
                                        <input
                                            id="email"
                                            type="email"
                                            className="input-box"
                                            placeholder="Enter Email Id"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                        {errors.email && <span className="error">{errors.email}</span>}
                                    </div>
                                </div>

                                <div className=" mt-5">
                                    <div className="d-flex flex-column">
                                        <label htmlFor="phoneNumber">Phone Number</label>
                                        <input
                                            id="phoneNumber"
                                            type="tel"
                                            className="input-box"
                                            placeholder="Enter Phone Number"
                                            value={formData.phoneNumber}
                                            onChange={handleInputChange}
                                        />
                                        {errors.phoneNumber && <span className="error">{errors.phoneNumber}</span>}
                                    </div>
                                </div>

                                <div className=" mt-5">
                                    <div className="d-flex flex-column">
                                        <label htmlFor="message">Message</label>
                                        <textarea
                                            id="message"
                                            className="input-box"
                                            placeholder="Enter Message"
                                            rows="3"
                                            value={formData.message}
                                            onChange={handleInputChange}
                                        />
                                        {errors.message && <span className="error">{errors.message}</span>}
                                    </div>
                                </div>

                                <div className=" my-5">
                                    <button type="submit" className="message-btn">Leave us a Message<i className="bi bi-arrow-right ms-3"></i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </section>
                </div>
                
                
               
            </div>
            <Footer />
        </div>

    )
}

export default ContactUs;
