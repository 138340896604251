import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";

function FAQAccordion() {
    const accordionData = [
        {
            id: 1,
            title: 'How does investing in startups work?',
            content: "Investing in startups involves providing capital to early-stage companies in exchange for equity. As the company grows and becomes more valuable, so does the value of your investment.",
        },
        {
            id: 2,
            title: 'What are the risks of investing in startups?',
            content: "Investing in startups can be risky, as many new businesses fail to become profitable. It's important to do your research and understand the risks before investing, and to only invest what you can afford to lose.",
        },
        {
            id: 3,
            title: 'How do I know which startups to invest in?',
            content: "Research is key when it comes to identifying promising startups to invest in. Look for companies with strong leadership, a solid business plan, and a clear path to profitability. It's also important to consider the market demand for the product or service the startup is offering.",
        },
        {
            id: 4,
            title: 'What is KYC and why is it required for investing?',
            content: " KYC stands for Know Your Customer, and it's a process used by financial institutions to verify the identity of their clients. It's required for investing to prevent fraud and money laundering, and to ensure that investments are only made by authorized individuals.",
        },
        {
            id: 5,
            title: 'How do I invest on Bizdateup?',
            content: 'To invest on Bizdateup, you need to create an account, complete the KYC process, and select a startup to invest in. You can then choose your investment option and the amount you want to invest.',
        },
        {
            id: 6,
            title: 'What are the different investment options available on Bizdateup?',
            content: "The different investment options available on Bizdateup include CCDs, CCPS and Direct Equity.",
        },
        {
            id: 7,
            title: "What is the minimum investment amount on Bizdateup?",
            content: "The minimum investment amount on Bizdateup varies depending on the startup and the investment option chosen. However, the minimum amount is usually Rs 50,000.",
        },
        {
            id: 8,
            title: "When do I receive returns on my investment?",
            content: "The returns on your investment depend on the investment option chosen and the startup's performance. Usually, returns are realized when the startup is sold, goes public, or distributes dividends.",
        }
    ];
    const navigate = useNavigate();

    const [openIndex, setOpenIndex] = useState(0);

    const handleToggle = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    const handleClick = () => {
        navigate("/contact");
      };

    return (
        <div>
            <div className="accordion" id="accordionExample">
                {accordionData.map((item, index) => (
                    <div className="accordion-item mb-3" key={item.id}>
                        <h2 className="accordion-header" id={`heading${item.id}`}>
                            <button
                                className={`accordion-button ${openIndex === index ? '' : 'collapsed'}`}
                                type="button"
                                onClick={() => handleToggle(index)}
                                aria-expanded={openIndex === index ? 'true' : 'false'}
                                aria-controls={`collapse${item.id}`}
                            >
                                {item.title}
                                <span className="ms-auto">
                                    {openIndex === index ? (
                                        <img src="/assets/images/accordion-minus.png" alt="img"/>
                                    ) : (
                                        <img src="/assets/images/accordion-plus.png" alt="img" />
                                    )}
                                </span>
                            </button>
                        </h2>
                        <div
                            id={`collapse${item.id}`}
                            className={`accordion-collapse collapse ${openIndex === index ? 'show' : ''}`}
                            aria-labelledby={`heading${item.id}`}
                            data-bs-parent="#accordionExample"
                        >
                            <div className="accordion-body">
                                {item.content}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="any-question">
                <div className="row">
                    <div className="col-lg-9">
                        <h5 className="heading">Still have questions?</h5>
                        <p className="content">Can’t find the answer you’re looking for? Please get in touch with our team.</p>
                    </div>
                    <div className="col-lg-3 d-flex flex-row justify-content-lg-end mt-4 mt-lg-0">
                        <button className="btn" onClick={handleClick}>Get in touch</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default FAQAccordion;
